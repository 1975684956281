import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
// Import PrimeVue lib
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
// Global css
import './assets/styles/global.css';

createApp(App)
  .use(PrimeVue)
  .use(ToastService)
  .use(DialogService)
  .use(ConfirmationService)
  .use(store)
  .use(router)
  .mount('#app');

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  Collection,
  CollectionResponse,
  CollectionsResponse,
  FetchParams,
  Review,
  ReviewResponse,
  Reviewer,
  ReviewerResponse,
  ReviewersResponse,
  ReviewSuggestionsResponse,
  ReviewSuggestionResponse,
} from '@/types/Reviews';

const URL = '/products/reviews/';

export default {
  getCollections: ({
    // search,
    page = 1,
    per_page = 10,
    immediate = true,
    ordering = '',
  }: // user_types = [3, 4],
  // status,
  FetchParams) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<CollectionsResponse>(
      `${URL}collections/`,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          ordering,
          // user_statuses: status,
          // search,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getCollection: ({ collection_id }: { collection_id: string }) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<CollectionResponse>(
      `${URL}collections/${collection_id}`,
      {
        method: 'GET',
        params: {},
      },
      DefaultAPI,
      {
        immediate: true,
      }
    );
  },

  createCollection: ({ payload }: { payload: Collection }) => {
    const { attachments, ...data } = payload;

    const bodyFormData = new FormData();
    bodyFormData.append('data', JSON.stringify(data));

    attachments?.forEach((attachment) =>
      bodyFormData.append('attachments', attachment)
    );

    return useAxios<ReviewResponse>(
      `${URL}collections/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      },
      DefaultAPI
    );
  },

  updateCollection: ({
    collection_id,
    payload,
  }: {
    payload: Collection;
    collection_id: string;
  }) => {
    const { attachments, ...data } = payload;

    const bodyFormData = new FormData();
    bodyFormData.append('data', JSON.stringify(data));

    attachments?.forEach((attachment) =>
      bodyFormData.append('attachments', attachment)
    );

    return useAxios<ReviewResponse>(
      `${URL}collections/${collection_id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      },
      DefaultAPI
    );
  },

  deleteCollection: ({ collection_id }: { collection_id: string }) => {
    return useAxios<null>(
      `${URL}collections/${collection_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },

  getReviewers: ({
    text,
    page = 0,
    per_page = 10,
    immediate = true,
  }: // user_types = [3, 4],
  // status,
  FetchParams) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<ReviewersResponse>(
      `${URL}reviewers/`,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          // user_statuses: status,
          text,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getReviewer: ({ reviewer_id }: { reviewer_id: string }) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<ReviewerResponse>(
      `${URL}reviewers/${reviewer_id}`,
      {
        method: 'GET',
        params: {},
      },
      DefaultAPI,
      {
        immediate: true,
      }
    );
  },

  createReviewer: ({ payload }: { payload: Reviewer }) => {
    const { attachments, ...data } = payload;

    const bodyFormData = new FormData();
    bodyFormData.append('data', JSON.stringify(data));

    if (attachments) {
      bodyFormData.append('attachments', attachments);
    }

    return useAxios<ReviewResponse>(
      `${URL}reviewers/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      },
      DefaultAPI
    );
  },

  updateReviewer: ({
    reviewer_id,
    payload,
  }: {
    payload: Reviewer;
    reviewer_id: string;
  }) => {
    const { attachments, ...data } = payload;

    const bodyFormData = new FormData();
    bodyFormData.append('data', JSON.stringify(data));

    if (attachments) {
      bodyFormData.append('attachments', attachments);
    }

    return useAxios<ReviewResponse>(
      `${URL}reviewers/${reviewer_id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      },
      DefaultAPI
    );
  },

  deleteReviewer: ({ reviewer_id }: { reviewer_id: string }) => {
    return useAxios<null>(
      `${URL}reviewers/${reviewer_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },

  getReview: ({ review_id = '', immediate = true }: FetchParams) => {
    if (!review_id) return;
    return useAxios<ReviewResponse>(
      `${URL}${review_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getReviews: ({
    collection_id,
    page = 0,
    per_page = 10,
    immediate = true,
  }: {
    page?: number;
    per_page?: number;
    immediate?: boolean;
    collection_id: string;
  }) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<ReviewersResponse>(
      `${URL}collections/${collection_id}/items/`,
      {
        method: 'GET',
        params: {
          page,
          per_page,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  createReview: ({
    review,
    collection_id,
  }: {
    review: Review;
    collection_id: string;
  }) => {
    return useAxios<ReviewResponse>(
      `${URL}collections/${collection_id}/items/`,
      {
        method: 'POST',
        data: review,
      },
      DefaultAPI
    );
  },

  updateReview: ({ review }: { review: Review }) => {
    return useAxios<ReviewResponse>(
      `${URL}${review.review_id}`,
      {
        method: 'PUT',
        data: review,
      },
      DefaultAPI
    );
  },

  deleteReview: ({ review_id }: { review_id: string }) => {
    return useAxios<null>(
      `${URL}${review_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },

  getReviewSuggestions: ({
    page = 0,
    per_page = 10,
    immediate = true,
    status,
  }: {
    page?: number;
    per_page?: number;
    immediate?: boolean;
    status?: string;
  }) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<ReviewSuggestionsResponse>(
      `${URL}suggestions/`,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          status,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getReviewSuggestion: ({
    suggestion_id,
    immediate = true,
    status,
  }: {
    suggestion_id?: string;
    immediate?: boolean;
    status?: string;
  }) => {
    // const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<ReviewSuggestionResponse>(
      `${URL}suggestions/${suggestion_id}`,
      {
        method: 'GET',
        params: {
          status,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  updateReviewSuggestion: ({
    suggestion_id,
    suggestion_status,
  }: {
    suggestion_id: string;
    suggestion_status: string;
  }) => {
    return useAxios<ReviewSuggestionResponse>(
      `${URL}suggestions/${suggestion_id}`,
      {
        method: 'PUT',
        data: { suggestion_status },
      },
      DefaultAPI
    );
  },
};

// const buildURLQuery = (obj: { [key: string]: string | number }) => {
//   return (
//     (Object.entries(obj).length > 0 ? '?' : '') +
//     Object.entries(obj)
//       .filter((pair) => pair[1])
//       .map((pair) => pair.map(encodeURIComponent).join('='))
//       .join('&')
//   );
// };

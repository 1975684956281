import { MutationTree } from 'vuex';
import { AuthState, AuthUser, AuthUserInfo } from './types';

export enum AuthMutations {
  SET_AUTH = 'SET_AUTH',
  SET_USER = 'SET_USER',
  SET_USER_INFO = 'SET_USER_INFO',
}

export const mutations: MutationTree<AuthState> = {
  [AuthMutations.SET_AUTH](state, payload: boolean) {
    state.authenticated = payload;
  },
  [AuthMutations.SET_USER](state, payload: AuthUser) {
    state.name = payload.name;
    state.email = payload.email;
  },
  [AuthMutations.SET_USER_INFO](state, payload: AuthUserInfo) {
    state.user_id = payload.user_id;
    state.user_type = payload.user_type;
    state.login = payload.login;
  },
};

import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { AuthState } from './types';
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state: AuthState = {
  name: '',
  email: '',
  authenticated: false,
  user_id: 0,
  user_type: 0,
  login: '',
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { getters } from './getters';
import { mutations } from './mutations';
import { IFilter } from './types';

// list of pages na their filters, based on Route name
export enum FilterActions {
  ALL_PRODUCTS = 'All products',
  NEW_PRODUCTS = 'New products',
  HISTORY = 'History',
  IMAGES_LOGS = 'Images logs',
  ALL_INGREDIENTS = 'All ingredients',
  NEW_INGREDIENTS = 'New ingredients',
  INGREDIENTS_MATCH = 'Ingredients match',
  BRANDS = 'Brands',
  HAIRLAB = 'HairLab',
  NEW_BRANDS = 'New brands',
  NOTIFICATIONS = 'All notifications',
  MISTAKES = 'All mistakes',
  USERS = 'All users',
  ALL_REVIEW_SUGGESTIONS = 'All review suggestions',
  ALL_COLLECTIONS = 'All collections',
  ALL_USER_REVIEWS = 'All user reviews',
}

const state: IFilter = {
  // default payload for request
  // search_input - is for "Search" component to detect with v-model
  filterSettings: {
    [FilterActions.ALL_PRODUCTS]: {
      per_page: 12,
      search_input: 'product_name',
    },
    [FilterActions.NEW_PRODUCTS]: {
      per_page: 12,
      search_input: 'name',
      status: 'Analyzing',
      order: 'number_of_scans desc; last_modified desc; created desc',
    },
    [FilterActions.HISTORY]: {
      per_page: 12,
      search_input: 'name',
      events: {
        key: 'Events',
        multiple: true,
        value: [
          { key: 'Created', value: 'create product from request' },
          { key: 'Confirmed', value: 'confirm auto created product' },
          { key: 'Rejected', value: 'reject product request' },
        ],
      },
      moderators: {
        key: 'Moderators',
        multiple: true,
        callback: 'getUsers',
        value: [],
      },
    },
    [FilterActions.IMAGES_LOGS]: {
      per_page: 60,
      search_input: 'name',
    },
    [FilterActions.ALL_INGREDIENTS]: {
      per_page: 12,
      search_input: 'canonical_name',
    },
    [FilterActions.NEW_INGREDIENTS]: {
      per_page: 12,
      search_input: 'canonical_name',
      only_without_score: true,
    },
    [FilterActions.INGREDIENTS_MATCH]: {
      per_page: 12,
      search_input: 'ingredient_name',
    },
    [FilterActions.BRANDS]: {
      per_page: 12,
      search_input: 'search',
      only_main_new: {
        key: '-',
        value: { only_main: false, only_new: false },
      },
    },
    [FilterActions.NEW_BRANDS]: {
      per_page: 12,
      search_input: 'search',
      only_main_new: {
        key: 'New',
        value: { only_main: false, only_new: true },
      },
    },
    [FilterActions.HAIRLAB]: {
      per_page: 12,
      order: 'num_scans desc; created desc',
    },
    [FilterActions.NOTIFICATIONS]: {
      per_page: 12,
      search_input: 'search',
    },
    [FilterActions.MISTAKES]: {
      per_page: 12,
      order: 'submission_time desc',
    },
    [FilterActions.USERS]: {
      per_page: 12,
      search_input: 'search',
    },
    [FilterActions.ALL_REVIEW_SUGGESTIONS]: {
      per_page: 12,
      status: 'pending',
      search_input: 'search',
    },
    [FilterActions.ALL_COLLECTIONS]: {
      per_page: 12,
      ordering: 'is_free desc; open_at asc',
    },
    [FilterActions.ALL_USER_REVIEWS]: {
      per_page: 10,
      search_input: 'search',
    },
  },

  // structure to build/display filter form
  filterForm: {
    [FilterActions.ALL_PRODUCTS]: {
      per_page: [12, 24, 48],
      words: [
        { name: 'Product name', value: '', key: 'product_name' },
        { name: 'Brand name', value: '', key: 'brand_name' },
      ],
      dropdowns: {
        status: {
          name: 'Statuses',
          key: 'status',
          values: [
            { key: '-', value: '-' },
            { key: 'Scored', value: 'Scored' },
            { key: 'Analyzing', value: 'Analyzing' },
            { key: 'Intermittent score', value: 'Intermittent score' },
          ],
        },
        is_popular: {
          name: 'Show popular',
          key: 'is_popular',
          values: [
            { key: '-', value: '-' },
            { key: 'No', value: false },
            { key: 'Yes', value: true },
          ],
        },
        product_rating: {
          name: 'Product rating',
          key: 'product_rating',
          values: [
            { key: '-', value: '-' },
            { key: 'Excellent', value: { rating_min: 76, rating_max: 100 } },
            { key: 'Good', value: { rating_min: 51, rating_max: 75 } },
            { key: 'Poor', value: { rating_min: 26, rating_max: 50 } },
            { key: 'Bad', value: { rating_min: 0, rating_max: 25 } },
          ],
        },
        moderation_status: {
          name: 'Moderation status',
          key: 'moderation_status',
          values: [
            { key: '-', value: '-' },
            { key: 'Confirmed', value: 'Confirmed' },
            { key: 'Not Confirmed', value: 'NotConfirmed' },
          ],
        },
      },
    },
    [FilterActions.NEW_PRODUCTS]: {
      per_page: [12, 24, 48],
      words: [
        { name: 'Product name', value: '', key: 'name' },
        { name: 'Brand name', value: '', key: 'brand_name' },
        { name: 'Country', value: '', key: 'country', autocomplete: true },
      ],
      dropdowns: {
        status: {
          name: 'Status',
          key: 'status',
          values: [
            { key: '-', value: '-' },
            { key: 'Creating', value: 'Creating' },
            { key: 'Analyzing', value: 'Analyzing' },
            { key: 'Approved', value: 'Approved' },
            { key: 'Rejected', value: 'Rejected' },
          ],
        },
        feature: {
          name: 'Feature',
          key: 'feature',
          values: [
            { key: '-', value: '-' },
            { key: 'HairLab', value: 'hair_lab' },
            { key: 'Photo scan', value: 'image_search' },
          ],
        },
        priority: {
          name: 'User status',
          key: 'priority',
          values: [
            { key: '-', value: '-' },
            { key: 'Paid', value: '10' },
            { key: 'Trial', value: '7' },
            { key: 'Ingr.', value: '9' },
            { key: 'Auto', value: '8' },
            { key: 'Free', value: '0' },
          ],
        },
        order: {
          name: 'Order',
          key: 'order',
          values: [
            {
              key: 'Scans: DESC',
              value: 'number_of_scans desc; last_modified desc; created desc',
            },
            {
              key: 'Scans ASC',
              value: 'number_of_scans asc; last_modified desc; created desc',
            },
            { key: 'Default', value: 'last_modified desc; created desc' },
          ],
        },
      },
    },
    [FilterActions.HISTORY]: {
      per_page: [12, 24, 48],
      words: [],
      dropdowns: {
        events: {
          name: 'Events',
          key: 'events',
          multiple: true,
          values: [
            { key: 'Created', value: 'create product from request' },
            { key: 'Confirmed', value: 'confirm auto created product' },
            { key: 'Rejected', value: 'reject product request' },
          ],
        },
        moderators: {
          name: 'Moderators',
          key: 'moderators',
          multiple: true,
          hasFilter: true,
          values: [],
        },
        date: {
          name: 'Date/Time range',
          key: 'date',
          datepicker: true,
          values: [],
        },
      },
    },
    [FilterActions.IMAGES_LOGS]: {
      per_page: [60, 120, 240],
      words: [
        {
          name: 'Clicks count',
          value: '',
          key: 'user_clicks_count',
          class: 'filter__field_narrow',
        },
      ],
      dropdowns: {
        only_main_new: {
          name: 'Error',
          key: 'error',
          values: [
            { key: '-', value: '-' },
            { key: 'True', value: true },
            { key: 'False', value: false },
          ],
        },
        date: {
          name: 'Date/Time range',
          key: 'date',
          datepicker: true,
          values: [],
        },
      },
    },
    [FilterActions.ALL_INGREDIENTS]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'Ingredient name', value: '', key: 'canonical_name' }],
      dropdowns: {
        only_without_score: {
          name: 'Without score only',
          key: 'only_without_score',
          values: [
            { key: 'No', value: false },
            { key: 'Yes', value: true },
          ],
        },
      },
    },
    [FilterActions.NEW_INGREDIENTS]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'Ingredient name', value: '', key: 'canonical_name' }],
      dropdowns: {
        only_without_score: {
          name: 'Without score only',
          key: 'only_without_score',
          values: [{ key: 'Yes', value: true }],
        },
      },
    },
    [FilterActions.INGREDIENTS_MATCH]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'Ingredient name', value: '', key: 'ingredient_name' }],
      dropdowns: {
        only_unmatched: {
          name: 'Only unmatched',
          key: 'only_unmatched',
          values: [
            { key: 'No', value: false },
            { key: 'Yes', value: true },
          ],
        },
      },
    },
    [FilterActions.BRANDS]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'Brand name', value: '', key: 'search' }],
      dropdowns: {
        only_main_new: {
          name: 'Main / New brands',
          key: 'only_main_new',
          values: [
            { key: '-', value: { only_main: false, only_new: false } },
            { key: 'Main', value: { only_main: true, only_new: false } },
            { key: 'New', value: { only_main: false, only_new: true } },
          ],
        },
      },
    },
    [FilterActions.NEW_BRANDS]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'Brand name', value: '', key: 'search' }],
      dropdowns: {
        only_main_new: {
          name: 'Main / New brands',
          key: 'only_main_new',
          values: [{ key: 'New', value: { only_main: false, only_new: true } }],
        },
      },
    },
    [FilterActions.HAIRLAB]: {
      per_page: [12, 24, 48],
      words: [],
      dropdowns: {
        status: {
          name: 'Status',
          key: 'status',
          values: [
            { key: '-', value: '-' },
            { key: 'In progress', value: 'in_progress' },
            { key: 'Completed', value: 'completed' },
          ],
        },
        order: {
          name: 'Order',
          key: 'order',
          values: [
            {
              key: 'Scans: DESC',
              value: 'num_scans desc; created desc',
            },
            {
              key: 'Scans: ASC',
              value: 'num_scans asc; created asc',
            },
          ],
        },
      },
    },
    [FilterActions.MISTAKES]: {
      per_page: [12, 24, 48],
      words: [],
      dropdowns: {
        status: {
          name: 'Status',
          key: 'status',
          values: [
            { key: '-', value: '-' },
            { key: 'Pending', value: 'pending' },
            { key: 'Processed', value: 'processed' },
          ],
        },
        order: {
          name: 'Order',
          key: 'order',
          values: [
            {
              key: 'Submission time: DESC',
              value: 'submission_time desc',
            },
            {
              key: 'Submission time: ASC',
              value: 'submission_time asc',
            },
          ],
        },
      },
    },
    [FilterActions.NOTIFICATIONS]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'Notification name', value: '', key: 'search' }],
    },
    [FilterActions.USERS]: {
      per_page: [12, 24, 48, 96],
      words: [{ name: 'User name', value: '', key: 'search' }],
      dropdowns: {
        status: {
          name: 'Status',
          key: 'status',
          values: [
            { key: '-', value: '-' },
            { key: 'Active', value: 'active' },
            { key: 'Blocked', value: 'blocked' },
            { key: 'Unconfirmed', value: 'unconfirmed' },
          ],
        },
      },
    },
    [FilterActions.ALL_REVIEW_SUGGESTIONS]: {
      per_page: [12, 24, 48],
      words: [],
      dropdowns: {
        status: {
          name: 'Status',
          key: 'status',
          values: [
            { key: '-', value: '-' },
            { key: 'Pending', value: 'pending' },
            { key: 'Processed', value: 'processed' },
          ],
        },
      },
    },
    [FilterActions.ALL_COLLECTIONS]: {
      per_page: [12, 24, 48],
      words: [],
      dropdowns: {
        order: {
          name: 'Order',
          key: 'ordering',
          values: [
            {
              key: 'Default',
              value: 'is_free desc; open_at asc',
            },
            {
              key: 'Default + Status',
              value: 'state asc; is_free desc; open_at asc',
            },
          ],
        },
      },
    },
    [FilterActions.ALL_USER_REVIEWS]: {
      per_page: [10, 20],
      words: [],
      dropdowns: {
        status: {
          name: 'State',
          key: 'states',
          values: [
            { key: '-', value: '-' },
            { key: 'Rejected', value: 'Rejected' },
            { key: 'Published', value: 'Published' },
            { key: 'Auto-rejected', value: 'Auto-rejected' },
            { key: 'Auto-published', value: 'Auto-published' },
            { key: 'Pending', value: 'Pending' },
            { key: 'Edited', value: 'Edited' },
          ],
        },
      },
    },
  },
};

export const filter: Module<IFilter, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
};

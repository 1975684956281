import { computed } from 'vue';
import { useDateFormat } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function tableHelpers(props?: IProps) {
  const formatDateTime = (date: Date | string) => {
    return date ? useDateFormat(date, 'MM/DD/YYYY HH:mm:ss').value : '-';
  };
  const getItemIndex = computed(() => {
    return (index: number) => {
      if (props) {
        const pages = (props as IProps).pages;
        return pages.current_page * pages.per_page + index + 1;
      }
      return 0;
    };
  });

  /* FILTER  */

  const store = useStore();
  const route = useRoute();
  const router = useRouter();
  const current_page = computed(
    () => parseInt(route?.params?.pageId.toString()) - 1 || 0
  );
  const requestPayload = computed(() =>
    store.getters['filter/getFilterSettings'](route.name)
  );

  return {
    store,
    router,
    route,
    getItemIndex,
    formatDateTime,
    current_page,
    requestPayload,
  };
}

interface IProps {
  pages: {
    current_page: number;
    per_page: number;
  };
}

import { DefaultAPI } from '@/api';
import TokenService from '@/api/auth/token';
import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { AuthMutations } from './mutations';
import { AuthTokens, AuthState, AuthUser } from './types';
import jwt_decode from 'jwt-decode';

export enum AuthActions {
  CHECK_USER = 'CHECK_USER',
  LOGIN_USER = 'LOGIN_USER',
  GET_USER = 'GET_USER',
  LOGOUT_USER = 'LOGOUT_USER',
  GET_USERS = 'GET_USERS',
}

export const actions: ActionTree<AuthState, RootState> = {
  async [AuthActions.CHECK_USER]({ commit }) {
    // getting token & refresh tokens
    const access_token = localStorage.getItem('token');
    const refresh_token = localStorage.getItem('refresh_token');

    if (!access_token || !refresh_token) {
      commit(AuthMutations.SET_AUTH, false);
      return;
    }

    // check refresh token for date & time expiration
    const { exp: refreshExp } = jwt_decode<any>(`${refresh_token}`);
    if (Date.now() >= refreshExp * 1000) {
      commit(AuthMutations.SET_AUTH, false);
      return;
    }

    // getting access token expiration date & time
    const { exp: accessExp } = jwt_decode<any>(`${access_token}`);
    const isExpired = Date.now() >= accessExp * 1000;
    if (!isExpired) {
      commit(AuthMutations.SET_AUTH, true);
      // get additional user data
      let user_info;
      try {
        user_info = await TokenService.getUser();
        if (!user_info) {
          throw new Error();
        }
      } catch {
        return false;
      }
      commit(AuthMutations.SET_USER_INFO, user_info);
      return;
    }

    commit(AuthMutations.SET_AUTH, true);
  },

  async [AuthActions.LOGIN_USER]({ commit }, cred: AuthTokens) {
    try {
      const user: AuthUser = {
        name: cred.name,
        email: cred.email,
      };

      // update tokens
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.setItem('token', cred.access_token);
      localStorage.setItem('refresh_token', cred.refresh_token);

      // update Axios API.
      // Note: token update now went to request interceptor for every request
      // just in case keep this code commented out.
      // DefaultAPI.defaults.headers.common[
      //   'Authorization'
      // ] = `Bearer ${cred.access_token}`;

      // update auth & user info
      commit(AuthMutations.SET_AUTH, true);
      commit(AuthMutations.SET_USER, user);
      // get additional user data
      let user_info;
      try {
        user_info = await TokenService.getUser();
        if (!user_info) {
          throw new Error();
        }
      } catch {
        console.warn('failed login');
        return false;
      }
      commit(AuthMutations.SET_USER_INFO, user_info);
    } catch (error) {
      commit(AuthMutations.SET_AUTH, false);
    }
  },

  [AuthActions.LOGOUT_USER]({ commit }) {
    // remove tokens
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');

    commit(AuthMutations.SET_AUTH, false);
  },

  async [AuthActions.GET_USERS]({ commit }, { search }: { search: string }) {
    const users = await TokenService.getUsers({ search });
  },
};

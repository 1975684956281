import { AxiosError } from 'axios';
import { ToastMessageOptions } from 'primevue/toast';

export type ToastData = ToastMessageOptions | null;
export type ToastError = AxiosError | null;

export interface ToastProps {
  error?: ToastError;
  message?: ToastData;
  life?: number;
}

export enum ToastSeverity {
  Info = 'info',
  Success = 'success',
  Warn = 'warn',
  Error = 'error',
}

export const ToastTimeout = 1250;

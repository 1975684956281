import { MutationTree } from 'vuex';
import { IFilter, IFilterPayload, IFilterStatus } from './types';

export const mutations: MutationTree<IFilter> = {
  setFilter(state, payload: IFilterPayload) {
    // restore prev payload + add new props from filter form
    // get rid of route in payload
    const { route, ...unitedPayload } = {
      ...state.filterSettings[payload?.route],
      ...payload,
    };

    // remove unneces.props (like '' or '-')
    // and transform dropdown to normal query string
    for (const prop in unitedPayload) {
      if (
        !(unitedPayload[prop] as IFilterStatus) ||
        (unitedPayload[prop] as IFilterStatus).value === '-' ||
        (unitedPayload[prop] as IFilterStatus).value === ''
      ) {
        // delete on
        delete unitedPayload[prop];
      }
      if (typeof unitedPayload[prop] === 'object') {
        // if multiselect
        if (Array.isArray(unitedPayload[prop])) {
          unitedPayload[prop] = {
            key: prop,
            multiple: true,
            value: unitedPayload[prop],
          } as IFilterStatus;
        } else {
          unitedPayload[prop] =
            (unitedPayload[prop] as IFilterStatus).value ?? unitedPayload[prop];
        }
      }
    }
    state.filterSettings[route] = { ...(unitedPayload as TFilter) };
  },
};

type TFilter = {
  [key: string]:
    | string
    | number
    | boolean
    | IFilterStatus
    | { [key: string]: number | boolean | string }
    | { [key: string]: number | boolean | string }[];
};

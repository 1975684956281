import { LoginAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import { Credentials, LoginResponse } from '@/types/Login';

const URL = '/token';

export default {
  loginUser: (cred: Credentials) => {
    return useAxios<LoginResponse>(
      `${URL}`,
      {
        method: 'POST',
        data: { ...cred },
      },
      LoginAPI
    );
  },

  authUserGoogle: async ({
    code,
    redirect_uri,
  }: {
    code: number;
    redirect_uri?: string;
  }) => {
    return useAxios<LoginResponse>(
      `${URL}`,
      {
        method: 'POST',
        data: { google_code: code, redirect_uri },
      },
      LoginAPI
    );
    // const response = await LoginAPI.post('/token', { google_code: code });
    // console.warn(response);
    // return { ...response?.data };
  },
};

<template>
  <div class="main-view">
    <SideBar />
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { SideBar } from '@/components/SideBar';

export default defineComponent({
  name: 'MainView',
  components: { SideBar },
  setup() {
    return {};
  },
});
</script>
<style lang="scss" scoped>
.main-view {
  display: flex;
  .sidebar {
    & + div {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
  &::after {
    content: '';
    position: fixed;
    opacity: 0;
    visibility: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.42);
    transition: all 0.1s;
  }
  &.overlay {
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }
}

header {
  grid-column: span 4;
  text-align: center;
  border: 1px solid #ddd;
}

nav {
  padding: 30px;
  border: 1px solid #ddd;
}

nav li {
  text-decoration: underline;
}

article {
  padding: 30px;
}
</style>

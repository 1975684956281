<template>
  <div class="login">
    <div class="login__bg">
      <img src="@/assets/images/plant.png" alt="plant img" />
    </div>
    <div class="login__form">
      <div class="login__greeting">
        <div class="hello-container">
          <img
            class="image"
            src="@/assets/images/tube.png"
            alt="cosmios logo"
          />
          <h1>Hello Again!</h1>
          <p>Log in to your account</p>
        </div>
        <div class="inputs">
          <div class="input p-input-icon-left">
            <i class="pi pi-user" />
            <InputText
              class="input-style p-inputtext-lg"
              placeholder="Login"
              type="text"
              v-model="state.login"
              :class="{ 'p-invalid': v$.login.$error }"
            />
            <small v-if="v$.login.$error" id="login-help" class="p-error">
              Please provide login.
            </small>
          </div>
          <div class="input p-input-icon-left">
            <i class="pi pi-lock" />
            <InputText
              class="input-style p-inputtext-lg"
              placeholder="Password"
              type="password"
              v-model="state.password"
              :class="{ 'p-invalid': v$.password.$error }"
            />
            <small v-if="v$.password.$error" id="password-help" class="p-error">
              Please provide password.
            </small>
          </div>
          <div class="input">
            <Button class="p-button-lg" label="Sign in" @click="submit" />
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-top: -10px;
          "
        >
          <div style="width: 45%; height: 1px; background: #7a8f85" />
          <p>or</p>
          <div style="width: 45%; height: 1px; background: #7a8f85" />
        </div>
        <div class="google">
          <div class="google__button" @click="authUserGoogle">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 9.20459C18 8.5664 17.9416 7.95277 17.833 7.36368H9.18367V10.845H14.1262C13.9133 11.97 13.2662 12.9232 12.2936 13.5614V15.8196H15.2616C16.9981 14.2528 18 11.9455 18 9.20459Z"
                fill="#4285F4"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.18368 18C11.6633 18 13.7421 17.1941 15.2616 15.8196L12.2936 13.5614C11.4712 14.1014 10.4193 14.4204 9.18368 14.4204C6.79175 14.4204 4.76717 12.8372 4.045 10.71H0.976813V13.0418C2.48795 15.9832 5.5937 18 9.18368 18Z"
                fill="#34A853"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.045 10.71C3.86132 10.17 3.75696 9.59321 3.75696 9.00003C3.75696 8.40685 3.86132 7.83004 4.04499 7.29004V4.95822H0.976809C0.354824 6.17322 0 7.54776 0 9.00003C0 10.4523 0.354828 11.8268 0.976813 13.0418L4.045 10.71Z"
                fill="#FBBC05"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.18368 3.57954C10.532 3.57954 11.7426 4.03363 12.6944 4.92545L15.3284 2.34409C13.738 0.891817 11.6591 0 9.18368 0C5.5937 0 2.48794 2.01686 0.976809 4.95822L4.04499 7.29004C4.76716 5.16277 6.79175 3.57954 9.18368 3.57954Z"
                fill="#EA4335"
              />
            </svg>
            <span style="margin-left: 10px">Sign in with Google</span>
          </div>
        </div>
      </div>
    </div>

    <NotificationToast />
  </div>
</template>

<script lang="ts">
import { googleSdkLoaded } from 'vue3-google-login';
import { AuthActions } from '@/store/modules/auth/actions';
import { AuthMutations } from '@/store/modules/auth/mutations';
import { defineComponent, reactive, computed, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { LoginService } from '@/services';
import {
  ToastSeverity,
  NotificationToast,
  useNotificationToast,
} from '@/components/Toasts';

export default defineComponent({
  name: 'LoginView',
  components: { InputText, Button, NotificationToast },
  setup() {
    const state = reactive({
      login: '',
      password: '',
    });
    const router = useRouter();
    const store = useStore();
    const { notification } = useNotificationToast();
    const codeClient = ref();
    const code = ref();

    // Validation rules
    const rules = computed(() => ({
      login: {
        required,
      },
      password: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, state);

    const createGoogleCodeClient = () => {
      googleSdkLoaded(async (google: any) => {
        codeClient.value = google.accounts.oauth2.initCodeClient({
          client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
          scope: 'email profile openid',
          callback: async (response: any) => {
            code.value = response.code;
            try {
              const loginResponse = await LoginService.authUserGoogle({
                code: code.value,
                redirect_uri: process.env.VUE_APP_REDIRECT_URL,
              });

              const error = loginResponse.error.value;
              const tokens = loginResponse.data.value?.data;

              if (error) {
                notification.add({
                  message: {
                    severity: ToastSeverity.Error,
                    summary: (error?.response?.data as any).detail,
                  },
                });
                return;
              }
              localStorage.clear();
              await store.dispatch(`auth/${AuthActions.LOGIN_USER}`, tokens);
              await router.push('/products/all/1');
            } catch {
              store.commit(`auth/${AuthMutations.SET_AUTH}`, false);
              return false;
            }
          },
        });
      });
    };

    const authUserGoogle = () => {
      codeClient.value.requestCode();
    };

    onMounted(async () => {
      await createGoogleCodeClient();
    });

    const submit = async () => {
      try {
        // Login succeded
        v$.value.$validate();
        if (!v$.value.$error) {
          const loginResponse = await LoginService.loginUser({
            login: state.login,
            password: state.password,
          });
          const error = loginResponse.error.value;
          const tokens = loginResponse.data.value?.data;

          if (error) {
            notification.add({
              message: {
                severity: ToastSeverity.Error,
                summary: (error?.response?.data as any).detail,
              },
            });
            return;
          }
          localStorage.clear();
          await store.dispatch(`auth/${AuthActions.LOGIN_USER}`, tokens);
          await router.push('/products/all/1');
        }
      } catch (error) {
        // Login failed
        store.commit(`auth/${AuthMutations.SET_AUTH}`, false);
      }
    };

    return { state, submit, v$, NotificationToast, authUserGoogle };
  },
});
</script>

<style lang="scss">
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.login__form {
      flex-direction: column;
      .login__greeting {
        max-width: 520px;
        width: 100%;
        .hello-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          h1 {
            font-size: 43px;
            margin: 38px 0 6px;
          }
          p {
            font-weight: bold;
            margin: 0 0 38px;
            letter-spacing: -0.11px;
          }
        }
      }
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .input {
      display: flex;
      flex-direction: column;
      width: 100%;
      border: none;
      padding: 0;
      margin-bottom: 10px;
      button {
        @extend .button-common, .tall;
        margin: 20px 0 0;
        span {
          transition: all 0s;
        }
      }
      input {
        &.input-style {
          @extend .input-common, .green;
          letter-spacing: 0.11px;
          padding: 0 24px 0 64px;
        }
      }
      i {
        &.pi {
          transform: translate(0, -50%);
          margin-top: 0;
          left: 24px;
          &::before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
          }
          &-user {
            &::before {
              background: url('~@/assets/images/icon-user.svg') no-repeat center
                center;
            }
          }
          &-lock {
            &::before {
              background: url('~@/assets/images/icon-password.svg') no-repeat
                center center;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
img {
  &.image {
    width: 116px;
    height: 116px;
    border-radius: 6px;
  }
}
.input {
  position: relative;
}
.google {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #d1d5db;
  background: white;
  transition: all 0.1s;
  border-radius: 16px;
  &:hover {
    background: #e2ede8;
  }
  &__button {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    height: 62px;
    font-weight: 600;
    color: #1f2937;
  }
}
</style>

<style lang="scss">
button,
.input {
  position: relative;
  .pi {
    position: relative;
    top: 0%;
    transform: translate(0, 0%);
  }
}
.search-bar {
  button,
  .input {
    position: relative;
    .pi {
      position: relative;
      top: 0%;
      transform: translate(0, 0%);
    }
  }
}
button,
.input {
  .pi {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>

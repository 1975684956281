import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  FetchParams,
  BrandsResponse,
  BrandDetailedResponse,
  Brand,
} from '@/types/Brand';

const URL = '/brands/';

export default {
  getBrand: ({ brand_id = '', immediate = true }: FetchParams) => {
    if (!brand_id) return;
    return useAxios<BrandDetailedResponse>(
      `${URL}${brand_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  getBrands: ({
    page = 0,
    per_page = 10,
    immediate = true,
    search,
    only_main_new,
  }: FetchParams) => {
    only_main_new = only_main_new?.value
      ? (only_main_new?.value as { [key: string]: boolean })
      : only_main_new;
    return useAxios<BrandsResponse>(
      URL,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          search,
          ...only_main_new,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  postBrand: ({
    name,
    main_brand_id,
  }: {
    name: string;
    main_brand_id: number;
  }) => {
    return useAxios<BrandsResponse>(
      `${URL}`,
      {
        method: 'POST',
        data: { name, main_brand_id },
      },
      DefaultAPI
    );
  },
  updateBrand: (brand: Brand) => {
    return useAxios<BrandsResponse>(
      `/brands/${brand.brand_id}`,
      {
        method: 'PUT',
        data: { name: brand.name, main_brand_id: brand.main_brand_id },
      },
      DefaultAPI
    );
  },
  deleteBrand: (brand_id: number) => {
    return useAxios<BrandsResponse>(
      `${URL}${brand_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },
  checkBrands: () => {
    return useAxios(
      '/brands/',
      {
        method: 'GET',
      },
      DefaultAPI
    );
  },
  getBrandSynonyms: ({
    page = 0,
    per_page = 100,
    immediate = true,
    brand_id,
  }: FetchParams) => {
    return useAxios<BrandsResponse>(
      `${URL}${brand_id}/synonyms`,
      {
        method: 'GET',
        params: {
          page,
          per_page,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
};

<template>
  <router-view />
</template>

<script lang="ts">
import { AuthActions } from '@/store/modules/auth/actions';
import { defineComponent, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'App',
  components: {
    /*LoginView, MainView*/
  },
  setup() {
    const store = useStore();
    const auth = computed(() => store.state.auth);

    onMounted(async () => {
      await store.dispatch(`auth/${AuthActions.CHECK_USER}`);
    });

    /* events fired on the drop targets */
    document.addEventListener(
      'dragover',
      (event) => {
        event.preventDefault();
      },
      false
    );

    return { auth };
  },
});
</script>

<style lang="scss">
/* DIALOG FORM */
body {
  font-family: Avenir, Helvetica, Arial, 'Roboto', sans-serif !important;
  overflow: hidden;
  .p-toast {
    top: 155px;
  }
  .p-multiselect-panel {
    .p-multiselect-items-wrapper {
      max-height: 171px !important;
    }
    .p-multiselect-items {
      padding: 0;
      .p-multiselect-item {
        padding: 0.65rem 1.25rem;
      }
    }
  }
  .p-dialog {
    &:not(.p-confirm-dialog) {
      padding: 22px;
      background: $grey-3;
      border-radius: 19px;
      .p-dialog-content,
      .p-dialog-header,
      .p-dialog-footer {
        padding: 0;
        background: none;
        .p-inputtext {
          @extend .input-common;
        }
        .ingredient {
          .p-inputtext {
            font-size: 19px;
          }
        }
      }
      .p-dialog-content {
        overflow: visible;
      }
      .p-dialog-header {
        text-align: center;
        justify-content: center;
        .p-dialog-title {
          margin: 12px 0 28px;
          font-size: 22px;
          font-weight: bold;
          color: $black;
        }
        .p-dialog-header-icons {
          position: absolute;
          top: 17px;
          right: 17px;
        }
      }
    }
    .p-dialog-footer {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .p-button {
      &.p-button-full-width {
        @extend .button-common;
        margin-top: 20px;
        width: 100%;
        height: 62px;
        .p-button-label {
          transition: all 0s;
          font-size: 16px;
          font-weight: 600;
        }
      }
      &.p-autocomplete-dropdown {
        background: $white;
        border-color: transparent;
        position: absolute;
        right: 1px;
        height: calc(100% - 2px);
        border: 0;
        top: 1px;
        border-radius: 16px;
        &:focus,
        &:active {
          outline: none;
          box-shadow: none;
        }
      }
      &.p-confirm-dialog-accept,
      &.p-confirm-dialog-reject {
        @extend .button-common;
        height: 40px;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
        display: flex;
        width: 91px;
        margin: 0 5px;
        .p-button-label {
          transition: all 0s;
          font-size: 16px;
          font-weight: 600;
        }
      }
      &.p-confirm-dialog-reject {
        background: $grey-green;
        border-color: $light-green-1;
        color: $black;
        &:hover {
          background: $medium-green;
        }
      }
    }
  }
  button {
    position: relative;
    .pi {
      position: relative;
      top: 0%;
      transform: translate(0, 0%);
    }
    &.p-image-preview-indicator {
      position: absolute;
    }
  }
}
.loader {
  background: white;
  width: calc(100% - 4px);
  height: calc(100% - 68px);
  top: 66px;
  left: 2px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  // z-index: -1;
}
</style>

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  FetchParams,
  NotificationsResponse,
  NotificationResponse,
  NotificationDetailedResponse,
  NotificationPreviewResponse,
  Notification,
} from '@/types/Notifications';

const URL = '/newsletters/';

export default {
  getNotification: ({ newsletter_id = '', immediate = true }: FetchParams) => {
    if (!newsletter_id) return;
    return useAxios<NotificationDetailedResponse>(
      `${URL}${newsletter_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  getNotifications: ({
    page = 0,
    per_page = 10,
    immediate = true,
  }: FetchParams) => {
    return useAxios<NotificationsResponse>(
      URL,
      {
        method: 'GET',
        params: { page, per_page },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  postNotification: (notification: Notification) => {
    return useAxios<NotificationResponse>(
      `${URL}`,
      {
        method: 'POST',
        data: { ...notification },
      },
      DefaultAPI
    );
  },
  updateNotification: (notification: Notification) => {
    return useAxios<NotificationResponse>(
      `${URL}${notification.newsletter_id}`,
      {
        method: 'PUT',
        data: { ...notification },
      },
      DefaultAPI
    );
  },
  sendNotification: ({
    newsletter_id,
    user_id,
  }: {
    newsletter_id: number;
    user_id: number;
  }) => {
    return useAxios<NotificationResponse>(
      `${URL}${newsletter_id}/send-to/${user_id}`,
      {
        method: 'POST',
      },
      DefaultAPI
    );
  },
  deleteNotification: (newsletter_id: number) => {
    return useAxios<NotificationResponse>(
      `${URL}${newsletter_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },
  getNotificationPreview: (newsletter_id: number) => {
    return useAxios<NotificationPreviewResponse>(
      `${URL}${newsletter_id}/alert`,
      {
        method: 'GET',
      },
      DefaultAPI
    );
  },
};

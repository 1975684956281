import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  AddProductIngredientParams,
  AddProductIngredientsParams,
  ProductIngredientFetchParams,
  ProductIngredientResponse,
} from '@/types/ProductIngredients';

const URL = '/products/';

export default {
  getProductIngredients: ({
    product_id = '',
    immediate = true,
    per_page = 100,
  }: ProductIngredientFetchParams) => {
    if (!product_id) return;
    return useAxios<ProductIngredientResponse>(
      `${URL}${product_id}/ingredients`,
      {
        method: 'GET',
        params: { per_page },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  addProductIngredients: ({
    product_id = '',
    payload,
  }: AddProductIngredientsParams) => {
    return useAxios<ProductIngredientResponse>(
      `${URL}${product_id}/ingredients`,
      {
        method: 'POST',
        data: {
          ingredients_names: payload.map((i) => i.name),
          index: payload[0].index,
        },
      },
      DefaultAPI
    );
  },

  deleteProductIngredient: ({
    product_id = '',
    payload,
  }: AddProductIngredientParams) => {
    return useAxios<ProductIngredientResponse>(
      `${URL}${product_id}/ingredients/${payload.ingredient_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },

  updateProductIngredient: ({
    product_id = '',
    payload,
  }: AddProductIngredientParams) => {
    return useAxios<ProductIngredientResponse>(
      `${URL}${product_id}/ingredients/${payload.ingredient_id}`,
      {
        method: 'PUT',
        data: { index: payload.index },
      },
      DefaultAPI
    );
  },

  deleteProductAllIngredients: ({ product_id }: { product_id: number }) => {
    return useAxios<ProductIngredientResponse>(
      `${URL}${product_id}/ingredients/clean`,
      {
        method: 'PATCH',
      },
      DefaultAPI
    );
  },
};

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import { FetchParams, CategoriesResponse } from '@/types/ICategory';

const URL = '/categories/';

export default {
  getCategories: ({
    page = 0,
    per_page = 1000,
    immediate = true,
    search,
    parent,
  }: FetchParams) => {
    return useAxios<CategoriesResponse>(
      URL,
      {
        method: 'GET',
        params: { page, per_page, search, parent },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
};

<template>
  <div>
    <h1>404</h1>
    <h2>Page not found</h2>
    <router-link :to="{ name: 'home' }">To main</router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PageNotFound',
  components: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1,
h2 {
  text-align: center;
}
</style>

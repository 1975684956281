import { ToastMessageOptions } from 'primevue/toast';
import { ref } from 'vue';
import { ToastProps, ToastSeverity, ToastData } from './types';

export const message = ref<ToastData>();

const getMessage = ({ message: msg }: ToastProps) => {
  if (!msg) return null;

  return {
    severity: msg?.severity || ToastSeverity.Success,
    summary: msg?.summary || '',
    detail: msg?.detail || '',
  };
};

export const getSuccessMessages = (summary: string): ToastMessageOptions => {
  const msg = {
    severity: ToastSeverity.Success,
    summary: summary || '',
    life: 1250,
  } as unknown as ToastMessageOptions;
  return msg;
};

export const getErrorMessages = (error: any): ToastMessageOptions => {
  const err = getError({ error }) as unknown as ToastMessageOptions;
  return err;
};

const getError = ({ error }: ToastProps) => {
  if (!error) return null;

  const summary = error?.message;
  const status = (error?.response?.data as any).status || '';
  let detail = (error?.response?.data as any).detail;
  if (Array.isArray(detail)) {
    detail = detail.reduce(
      (prev: string, curr: any) => `${prev}${curr?.loc[1]}: ${curr?.msg}\n\n`,
      ''
    );
  }
  detail = `${detail}\n\nStatus: ${status}`;

  return {
    severity: ToastSeverity.Error,
    summary: summary,
    detail: detail,
  };
};

export const useNotificationToast = () => {
  const add = (props: ToastProps) => {
    if (!props) return;
    if (!props.error) {
      message.value = getMessage(props);
      return;
    }
    message.value = getError(props);
  };

  const notification = { add };

  return { notification };
};

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  FetchParams,
  IngredientsResponse,
  IngredientResponse,
  IngredientDetailedResponse,
  Ingredient,
} from '@/types/Ingredients';

const URL = '/ingredients/';

export default {
  getIngredient: ({
    ingredient_info_id = '',
    immediate = true,
  }: FetchParams) => {
    if (!ingredient_info_id) return;
    return useAxios<IngredientDetailedResponse>(
      `${URL}${ingredient_info_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  getIngredients: ({
    page = 0,
    per_page = 10,
    immediate = true,
    canonical_name,
    only_without_score,
  }: FetchParams) => {
    return useAxios<IngredientsResponse>(
      URL,
      {
        method: 'GET',
        params: { page, per_page, canonical_name, only_without_score },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  postIngredient: (ingredient: Ingredient) => {
    return useAxios<IngredientResponse>(
      `${URL}`,
      {
        method: 'POST',
        data: { ...ingredient },
      },
      DefaultAPI
    );
  },
  updateIngredient: (ingredient: Ingredient) => {
    return useAxios<IngredientResponse>(
      `${URL}${ingredient.ingredient_info_id}`,
      {
        method: 'PUT',
        data: { ...ingredient },
      },
      DefaultAPI
    );
  },
  deleteIngredient: (ingredient_info_id: number) => {
    return useAxios<IngredientResponse>(
      `${URL}${ingredient_info_id}`,
      {
        method: 'DELETE',
      },
      DefaultAPI
    );
  },
};

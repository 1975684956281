import { RootState } from '@/store/types';
import { GetterTree } from 'vuex';
import { IFilter, IFilterForm, IFilterStatus } from './types';

export const getters: GetterTree<IFilter, RootState> = {
  getFilterSettings(state): (route: string) => {
    [key: string]:
      | string
      | boolean
      | number
      | IFilterStatus
      | { [key: string]: number | boolean | string }
      | { [key: string]: number | boolean | string }[];
  } {
    return (route) => {
      // console.warn(state.filterSettings[route]);
      return state.filterSettings[route];
    };
  },
  getFilterForm(state): (route: string) => IFilterForm {
    return (route) => state.filterForm[route];
  },
};

<template>
  <Toast>
    <template #message="slotProps">
      <div class="toast-container">
        <div class="toast-summary">
          <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
          <h4>{{ slotProps.message.summary }}</h4>
          <div class="toast-detail">{{ slotProps.message.detail }}</div>
          <div
            v-if="slotProps.message.severity === 'info'"
            class="grid p-fluid toast-buttons"
          >
            <div class="col-6">
              <Button
                class="p-button-success"
                label="Keep"
                @click="onConfirm"
              ></Button>
            </div>
            <div class="col-6">
              <Button
                class="p-button-secondary"
                label="Reset"
                @click="onReset"
              ></Button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Toast>
</template>
<script lang="ts">
import { defineComponent, watch } from 'vue';
import { message } from './useNotificationToast';
import Toast, { ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { tableHelpers } from '@/composables/tableHelpers';
import Button from 'primevue/button';

export default defineComponent({
  name: 'NotificationToast',
  components: {
    Toast,
    Button,
  },
  setup() {
    const toast = useToast();
    const { route, router } = tableHelpers();

    watch(message, (value) => {
      if (value) {
        toast.add(message.value as ToastMessageOptions);
        if (message?.value?.severity === 'success') {
          setTimeout(() => {
            toast.removeAllGroups();
          }, 2500);
        }
      }
    });

    const onConfirm = () => {
      toast.removeAllGroups();
    };

    const onReset = () => {
      localStorage.removeItem(route.params.id as string);
      toast.removeAllGroups();
      router.go(0);
    };

    return {
      onConfirm,
      onReset,
    };
  },
});
</script>
<style lang="scss" scoped>
.toast-container {
  display: flex;
  width: 90%;
  position: relative;
}
.toast-summary {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.toast-detail {
  white-space: pre-wrap;
  width: 100%;
  text-overflow: ellipsis;
  position: relative;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toast-buttons {
  margin-top: 20px;
}
</style>

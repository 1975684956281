import { LoginAPI, DefaultAPI } from '@/api';
import { Credentials } from './types';
import { useAxios } from '@vueuse/integrations/useAxios';

export interface FetchParams {
  page?: number;
  per_page?: number;
  immediate?: boolean;
  search?: string;
  status?: string;
  user_types?: number[];
}

export interface IUser {
  user_id: number;
  login: string;
  email: string;
}
export interface PagesInfo {
  current_page: number;
  total_items: number;
  per_page: number;
}
export interface FetchResponse {
  status: number;
  pages_info: PagesInfo;
  data: IUser[];
}

export interface UserResponse {
  data: IUser;
  status: number;
}

export default {
  getToken: async (creds: Credentials) => {
    let response;
    try {
      response = await LoginAPI.post('/token', creds);
    } catch (err) {
      // console.log('get token error', err);
      return { token: '' };
    }
    return { token: response?.data?.data?.access_token || '' };
  },

  refreshToken: async () => {
    let response;
    try {
      const refresh_token = localStorage.getItem('refresh_token');
      response = await LoginAPI.patch('/token', { refresh_token });

      // get tokens
      const t = response?.data?.data?.access_token;
      const rt = response?.data?.data?.refresh_token;

      // update tokens
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      localStorage.setItem('token', t);
      localStorage.setItem('refresh_token', rt);

      // update Axios API
      LoginAPI.defaults.headers.common.Authorization = `Bearer ${t}`;
      DefaultAPI.defaults.headers.common.Authorization = `Bearer ${t}`;
    } catch (err) {
      // delete tokens
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      return { token: '' };
    }
    return { token: response?.data?.data?.access_token || '' };
  },

  getUser: async () => {
    let response;
    const token = localStorage.getItem('token');
    LoginAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    try {
      response = await LoginAPI.get('/users/me');
    } catch (err) {
      return null;
    }
    return { ...response?.data?.data };
  },

  getUsers: async ({
    search,
    per_page = 100,
    user_types = [1],
  }: {
    search: string;
    per_page?: number;
    user_types?: number[];
  }) => {
    const selectedUserTypes = '&user_types=' + user_types.join('&user_types=');
    let response;
    try {
      response = await LoginAPI.get(
        `/users/${buildURLQuery({
          search,
          per_page,
        })}&${selectedUserTypes}`
      );
    } catch (err) {
      return null;
    }
    return { ...response?.data?.data };
  },

  fetchUsers: ({
    search,
    page = 1,
    per_page = 10,
    immediate = true,
    user_types = [3, 4],
    status,
  }: FetchParams) => {
    const selectedUserTypes = 'user_types=' + user_types.join('&user_types=');
    return useAxios<FetchResponse>(
      `/users/?${selectedUserTypes}`,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          user_statuses: status,
          search,
        },
      },
      LoginAPI,
      {
        immediate,
      }
    );
  },

  fetchUser: ({
    user_id,
    immediate = true,
  }: {
    user_id: string;
    immediate?: boolean;
  }) => {
    return useAxios<FetchResponse>(
      `/users/${user_id}`,
      {
        method: 'GET',
        params: {},
      },
      LoginAPI,
      {
        immediate,
      }
    );
  },

  updateUser: async ({
    user_id,
    user_type,
    status,
  }: {
    user_id: number;
    user_type: number;
    status: string;
  }) => {
    return useAxios<UserResponse>(
      `/users/${user_id}`,
      {
        method: 'PUT',
        data: { status, user_type },
      },
      LoginAPI
    );
  },
};

const buildURLQuery = (obj: { [key: string]: string | number }) => {
  return (
    (Object.entries(obj).length > 0 ? '?' : '') +
    Object.entries(obj)
      .filter((pair) => pair[1])
      .map((pair) => pair.map(encodeURIComponent).join('='))
      .join('&')
  );
};

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  FetchParams,
  IngredientsMatchResponse,
  IngredientMatchDetailedResponse,
  IngredientMatch,
} from '@/types/ingredientsMatch';

const URL = '/ingredients-match/';

export default {
  getIngredientMatch: ({
    ingredient_id = '',
    immediate = true,
  }: FetchParams) => {
    if (!ingredient_id) return;
    return useAxios<IngredientMatchDetailedResponse>(
      `${URL}${ingredient_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  getIngredientsMatch: ({
    page = 0,
    per_page = 10,
    immediate = true,
    ingredient_name,
    only_unmatched,
    only_scored,
  }: FetchParams) => {
    return useAxios<IngredientsMatchResponse>(
      URL,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          ingredient_name,
          only_unmatched,
          only_scored,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  createIngredientMatch: (ingredient: IngredientMatch) => {
    return useAxios<IngredientsMatchResponse>(
      URL,
      {
        method: 'POST',
        data: {
          name: ingredient.name,
          ingredient_info_id: ingredient.ingredient_info_id,
        },
      },
      DefaultAPI
    );
  },
  updateIngredientMatch: (ingredient: IngredientMatch) => {
    return useAxios<IngredientsMatchResponse>(
      `${URL}${ingredient.ingredient_id}`,
      {
        method: 'PUT',
        data: { ingredient_info_id: ingredient.ingredient_info_id },
      },
      DefaultAPI
    );
  },
};

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import { countries } from 'countries-list';
import {
  FetchParams,
  RejectParams,
  CreateProductParams,
  CaptureRequestParams,
  ProductRequestResponse,
  ProductRequestDetailedResponse,
  IngredientResponse,
} from '@/types/ProductRequests';

const URL = '/products/requests/';
const INGREDIENTS_STATUS_URL = '/utils/get-ingredient-status';

export default {
  getProductRequest: ({
    product_request_id = '',
    immediate = true,
  }: FetchParams) => {
    if (!product_request_id) return;
    return useAxios<ProductRequestDetailedResponse>(
      `${URL}${product_request_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getProductRequests: ({
    page = 0,
    per_page = 10,
    immediate = true,
    name,
    brand_name,
    status,
    priority,
    order,
    country,
    feature,
  }: FetchParams) => {
    const countrySymbols =
      Object.entries(countries).find((c) => c[1].name === country)?.[0] || null;
    return useAxios<ProductRequestResponse>(
      URL,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          name,
          brand_name,
          status,
          priority,
          country: countrySymbols,
          order: order?.value || order,
          feature,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  createProduct: ({ product_request_id, payload }: CreateProductParams) => {
    return useAxios<ProductRequestResponse>(
      `${URL}${product_request_id}/create-product`,
      {
        method: 'PATCH',
        data: { ...payload },
      },
      DefaultAPI
    );
  },

  rejectProductRequest: ({ product_request_id, payload }: RejectParams) => {
    return useAxios<ProductRequestResponse>(
      `${URL}${product_request_id}/reject`,
      {
        method: 'PATCH',
        data: { ...payload },
      },
      DefaultAPI
    );
  },

  captureProductRequest: ({ product_request_id }: CaptureRequestParams) => {
    return useAxios<ProductRequestDetailedResponse>(
      `${URL}${product_request_id}/capture`,
      {
        method: 'PATCH',
        data: {},
      },
      DefaultAPI
    );
  },

  getIngredientStatus: ({ name = '', immediate = true }: FetchParams) => {
    if (!name) return;
    return useAxios<IngredientResponse>(
      `${INGREDIENTS_STATUS_URL}`,
      {
        method: 'GET',
        params: { name },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
};

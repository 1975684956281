<template>
  <router-link
    :to="{ name: to, params: params }"
    class="link"
    :class="{ active: isActive }"
  >
    <slot />
    <span v-if="notification" class="sidebar__notification">
      {{ notification }}
    </span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue';
import { RouteParamsRaw, useRoute } from 'vue-router';

export default defineComponent({
  name: 'SideBarLink',
  props: {
    to: { type: String, required: true },
    alt: { type: Array as PropType<string[]> },
    params: { type: Object as PropType<RouteParamsRaw> },
    notification: { type: Number },
  },
  setup(props) {
    const route = useRoute();
    const isActive = computed(
      () =>
        route.path === props.to ||
        props?.alt?.find((str) => route.path.includes(str))
    );
    return { isActive };
  },
});
</script>
<style lang="scss" scoped>
.link {
  @extend .button-common;
  display: flex;
  align-items: center;
  padding: 0 18px;
  margin-bottom: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.1s;
  color: $black;
  background: $grey-green;
  border-color: $light-green-1;
  letter-spacing: -0.11px;
  font-weight: bold;
  // border-radius: 15px;
  &:hover {
    background: $medium-green;
    .sidebar__notification {
      color: $white;
    }
  }
  &.active {
    color: $white;
    background: $dark-green;
    border: 1px solid $dark-green;
    .sidebar__notification {
      color: $dark-green;
      background: $white;
    }
  }
  .sidebar__notification {
    color: $white;
    background: $green-2;
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 14px;
    margin-left: auto;
  }
}
</style>

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import { FetchParams, DictionariesResponse } from '@/types/Dictionary';

const SKIN_TYPES = '/skin-types/';
const QUANTITY_UNITS = '/quantity-units/';

export default {
  getSkinTypes: ({
    page = 0,
    per_page = 10,
    immediate = true,
    search,
    title,
  }: FetchParams) => {
    return useAxios<DictionariesResponse>(
      SKIN_TYPES,
      {
        method: 'GET',
        params: { page, per_page, search, title },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
  getQuantityUnits: ({
    page = 0,
    per_page = 10,
    immediate = true,
    search,
    title,
  }: FetchParams) => {
    return useAxios<DictionariesResponse>(
      QUANTITY_UNITS,
      {
        method: 'GET',
        params: { page, per_page, search, title },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
};

import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import {
  FetchParams,
  ProductsResponse,
  ProductDetailedResponse,
  UpdateProductParams,
  ProductBarcodesResponse,
  MysteryProductsResponse,
} from '@/types/Products';

const URL = '/products/';

export default {
  getProduct: ({ product_id = '', immediate = true }: FetchParams) => {
    if (!product_id) return;
    return useAxios<ProductDetailedResponse>(
      `${URL}${product_id}`,
      {
        method: 'GET',
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getProducts: ({
    page = 0,
    per_page = 10,
    immediate = true,
    product_name,
    brand_name,
    status,
    product_rating,
    moderation_status,
    is_popular,
  }: FetchParams) => {
    return useAxios<ProductsResponse>(
      URL,
      {
        method: 'GET',
        params: {
          page,
          per_page,
          product_name,
          brand_name,
          status,
          moderation_status,
          is_popular,
          ...product_rating,
        },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getMysteryProducts: ({
    start,
    end,
    immediate = true,
  }: {
    start: string;
    end: string;
    immediate?: boolean;
  }) => {
    return useAxios<MysteryProductsResponse>(
      `mystery-product/`,
      {
        method: 'GET',
        params: { start, end },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  getMysteryProduct: ({ day }: { day: string }) => {
    return useAxios<{
      status: number;
      data: {
        day: string;
        product_id: number;
        product_name: string;
        brand_name: string;
        product_rating: number;
      };
    }>(`mystery-product/${day}`, {
      method: 'GET',
      params: {},
    });
  },

  getMysteryProductDuplicates: ({ product_id }: { product_id: number }) => {
    return useAxios<{
      product_id: number;
      dates: string[];
    }>(
      `mystery-product/${product_id}/duplicates`,
      {
        method: 'GET',
        params: {},
      },
      DefaultAPI,
      {
        immediate: true,
      }
    );
  },

  createProduct: ({ payload }: UpdateProductParams) => {
    const { attachments, ...product_data } = payload;

    const bodyFormData = new FormData();
    bodyFormData.append('product_data', JSON.stringify(product_data));

    if (attachments) {
      bodyFormData.append('attachments', attachments);
    }

    return useAxios<ProductDetailedResponse>(
      `${URL}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      },
      DefaultAPI
    );
  },

  addMysteryProduct: ({
    day,
    product_id,
  }: {
    day: string;
    product_id: string;
  }) => {
    return useAxios<ProductBarcodesResponse>(
      `mystery-product/`,
      {
        method: 'POST',
        data: { day, product_id },
      },
      DefaultAPI
    );
  },

  updateMysteryProduct: ({
    day,
    product_id,
  }: {
    day: string;
    product_id: string;
  }) => {
    return useAxios<{
      status: number;
      data: { day: string; product_id: number };
    }>(
      `mystery-product/${day}`,
      {
        method: 'PUT',
        data: { product_id },
      },
      DefaultAPI
    );
  },

  updateProduct: ({ product_id, payload }: UpdateProductParams) => {
    const { attachments, ...product_data } = payload;

    const bodyFormData = new FormData();
    bodyFormData.append('product_data', JSON.stringify(product_data));

    if (attachments) {
      bodyFormData.append('attachments', attachments);
    }

    return useAxios<ProductsResponse>(
      `${URL}${product_id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: bodyFormData,
      },
      DefaultAPI
    );
  },

  getProductBarcodes: ({
    page = 0,
    per_page = 100,
    product_id,
    immediate = true,
  }: FetchParams) => {
    return useAxios<ProductBarcodesResponse>(
      `${URL}${product_id}/barcodes/`,
      {
        method: 'GET',
        params: { page, per_page, product_id },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  createProductBarcode: ({ format, code, product_id }: FetchParams) => {
    return useAxios<ProductBarcodesResponse>(
      `${URL}${product_id}/barcodes/`,
      {
        method: 'POST',
        data: { format, code },
      },
      DefaultAPI
    );
  },

  deleteProductBarcode: ({ format, code, product_id }: FetchParams) => {
    return useAxios<ProductBarcodesResponse>(
      `${URL}${product_id}/barcodes/${format}/${code}`,
      {
        method: 'delete',
      },
      DefaultAPI
    );
  },

  deleteMysteryProduct: ({ day }: { day: string }) => {
    return useAxios<null>(
      `mystery-product/${day}`,
      {
        method: 'DELETE',
        data: {},
      },
      DefaultAPI
    );
  },
};
